import React from "react";
import Layout from "../../templates/layout";
import SEO from "../../components/seo/seo";
import Hero from "../../components/hero/hero";
import IconCard from "../../components/iconCard/iconCard";
import HaveQuestions from "../../components/haveQuestions/haveQuestions";
import SarclisaLogo from "../../images/logos/sarclisa_patient.svg";
// import HeroIcon from "../../images/icons/icon-forms-sarclisa-hero.svg";
import HeroIcon from "../../images/icons/icon-resource-sarclisa-hero.svg";
import QuestionsIcon from "../../images/icons/icon-questions-sarclisa.svg";
import enrollmentForm from "../../images/icons/elitek/enrollment-form.png"
import spanishEnrollmentForm from "../../images/icons/elitek/spanish-enrollment-form.png";

import PatientNavigationFlashcard from "../../images/icons/sarclisa/patient-resource-navigation-flashcard.png";
import patientOverviewFlashcard from "../../images/icons/elitek/patientOverviewIcon.jpg";
import spanishOverviewFlashcard from "../../images/icons/sarclisa/spanishOverviewFlashcard.jpg";

import ExpandingCard from "../../components/ExpandingCard/ExpandingCard";


import Isi from './components/Isi'
import ResourceLinks from './components/ResourceLinks'
import BackToTop from "../../components/backToTop/BackToTop";

const pageContent = () => (
  <Layout pageid="page-forms" pageClass="patient-sarclisa-support mobile-height-hero">
    <SEO
      title="Find CareASSIST patient resources for SARCLISA® (isatuximab-irfc) | Patient Site"
      keywords="CareASSIST, Sanofi, SARCLISA® (isatuximab-irfc), Patient Site, Resource Support"
      description="Find resources and support options for SARCLISA with CareASSIST. See Prescribing Info, including Patient Info"
    />

    <Hero
      headline="Resource support"
      brandLogo={SarclisaLogo}
      brandAlt="Sarclisa Logo"
      heroImgClass="hero-icon"
      hasLogo={true}
      hasIcon={true}
      heroIcon={HeroIcon}
      hasButton={false}
      iconAlt="Resource Support Icon"
      copy="Get connected to independent support organizations and resources to support your treatment journey"
    />


<section className="program-download mobile-p-t-0">
      <div className="program-download-inner sarclisa">
        <div className="expanding-card-content header m-b-30 p-l-35 mobile-p-l-0">
          <h2 className="m-t-0  mobile-p-l-15 lh100">Program essentials</h2>
        </div>
        <div className="grid-cols-sm-1 grid-cols-2 gap-30 p-l-35 p-r-35">
                <IconCard
                  icon={enrollmentForm}
                  alt="Enrollment form"
                  cardClass="download-card sarclisa"
                  cardTitle="Enrollment form"
                  cardText=""
                  isExternalLink={true}
                  link="../../pdfs/careassist-program-enrollment-form.pdf"
                  linkClass="download-icon"
                  linkTitle="Download the enrollment form"
                  isBlur={true}
                  linkId="patient-sarclisa-resource-enrollment-form"
                />
                <IconCard
                  icon={spanishEnrollmentForm}
                  alt="Spanish enrollment form"
                  cardClass="download-card sarclisa"
                  cardTitle="Spanish enrollment form"
                  cardText=""
                  isExternalLink={true}
                  link="../../pdfs/careassist-spanish-enrollment-form.pdf"
                  linkClass="download-icon"
                  linkTitle="Download the Spanish enrollment form"
                  isBlur={true}
                  linkId="patient-sarclisa-resource-spansih-enrollment-form"
                />
              <IconCard
                icon={patientOverviewFlashcard}
                alt="CareASSIST patient overview flashcard"
                cardClass="download-card sarclisa"
                cardTitle="CareASSIST patient overview flashcard"
                cardText=""
                isExternalLink={true}
                link="../../pdfs/careassist-patient-support-program-overview-resource-patient.pdf"
                linkClass="download-icon"
                linkTitle=""
                isBlur={true}
                linkId="patient-sarclisa-resource-flashcard"
              />
              {/* <IconCard
                icon={spanishOverviewFlashcard}
                alt="CareASSIST Spanish patient overview flashcard"
                cardClass="download-card sarclisa"
                cardTitle="CareASSIST Spanish patient overview flashcard"
                cardText=""
                isExternalLink={false}
                link="../../pdfs/careassist-patient-support-program-overview-resource-spanish-version.pdf"
                linkClass="download-icon"
                linkTitle=""
              />  */}
        </div>
      </div>
    </section>

    <section className="content-section p-t-20" id="overview">
        <div className="expanding-card-content header">
        <h2 className="fs-32">Online resources*</h2>
        </div>
        <ResourceLinks/>  
        <Isi/>
      <p className="pi-paragraph">Please see full <a id="patient-sarclisa-pi" href="https://products.sanofi.us/Sarclisa/sarclisa.pdf" target="_blank" rel="noreferrer">Prescribing Information</a>, including <a id="patient-sarclisa-patient-info" href="https://products.sanofi.us/Sarclisa/sarclisa_patient_information.pdf" target="_blank">Patient Information</a>.</p>
      <HaveQuestions icon={QuestionsIcon} />
    </section>
      <div className="back-to-top-container">
          <BackToTop />
      </div>
  </Layout>
);

export default pageContent;
